<div class="client-invitation-banner-container">
  <div class="row">
    <div class="col-12 pointer-cursor" (click)="openClientInvitationModal()">
      @if (innerWidth <= 650 || innerWidth >= 1400) {
        <div class="br-16 p-15 invite-friends-card">
          <div class="line"></div>

          <div class="row align-items-center">
            <div class="col-8 d-flex flex-column">
              <div class="text-white text-center mb-2">Freunde einladen</div>

              <div class="d-md-none text-center text-white text-12">
                Bitte hier klicken und deinen Berater weiterempfehlen.
              </div>
            </div>

            <div class="col-4 d-flex justify-content-end align-items-center">
              <img
                ngSrc="https://static-assets.vinlivt.de/icons/premium/purple/320/Gift%202.png"
                width="65"
                height="65"
                alt="vinlivt"
              />
            </div>
          </div>
        </div>
      } @else {
        <div
          class="br-16 d-flex align-items-center justify-content-center"
          style="width: 45px; height: 45px; background: #242abe"
        >
          <img
            ngSrc="https://static-assets.vinlivt.de/icons/premium/purple/320/Gift%202.png"
            width="35"
            height="35"
            alt="vinlivt"
          />
        </div>
      }
    </div>
  </div>
</div>
